import { useHistory } from "react-router-dom";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonSpinner,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonLabel,
  IonInput,
  IonImg,
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useState, useRef } from "react";
import { add as addIcon, print as printIcon } from "ionicons/icons";
import { gql, useQuery, useMutation } from "@apollo/client";
import Moment from "moment";
import "moment/locale/es";
import AuthService from "../services/auth.service";
import { useTranslation, withTranslation } from "react-i18next";
import config from "../config";
import "./Pages.css";

const GET_USER = gql`
  query usuario($usuarioId: String) {
    viewer {
      usuario(usuarioId: $usuarioId) {
        id
        comprobantes(sort: FECHA_DESC) {
          edges {
            node {
              id
              comentario
              urlImagen
              fecha
              estatus
              pagoId
            }
          }
        }
        habitaResidente {
          id
          habitacion {
            id
            nombre
            superficie
            cuota
            referenciaBancaria
            saldo
            habitaResidente {
              residente {
                id
                nombre
                paterno
                materno
                email
              }
            }
            tipoVivienda {
              id
              nombre
            }
            condominio {
              id
              empresa
            }
            torre {
              id
              nombre
              condominio {
                id
                empresa
              }
            }
            cuotas(filters: { estatusNe: "P" }) {
              edges {
                node {
                  id
                  residente {
                    id
                    nombre
                  }
                  formaCuota
                  monto
                  fecha
                  concepto
                  estatus
                  cuotaXMetro
                  contTipoCuota {
                    id
                    nombre
                  }
                  pagos {
                    edges {
                      node {
                        id
                        recibo
                        monto
                      }
                    }
                  }
                  recargos(filters: { estatus: "D" }) {
                    edges {
                      node {
                        id
                        deudaDe
                        deudaA
                        otrasDeudasVigentes
                        cantidad
                        cantidadTipo
                        aplicarA
                        soloMayoresA
                        conContCuotas {
                          id
                          monto
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const SINGLE_UPLOAD_MUTATION = gql`
  mutation uploadFileMutation($file: Upload!) {
    uploadFile(file: $file) {
      success
      fileName
    }
  }
`;

interface PageProps {
  pageName: string | undefined;
}

const PagosPage: React.FC<PageProps> = ({ pageName }, location) => {
  const history = useHistory();
  const { t } = useTranslation();
  const fileInput = useRef(null);
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const [condominioId, setCondominioId] = useState(
    currentUser.condominioId ? currentUser.condominioId : 0
  );
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [imageChange, setImageChange] = useState(false);
  const [number, setNumber] = useState<number>();
  const [cuotasPendientes, setCuotasPendientes] = useState<any[]>([]);
  const [totalPagoPendiente, setTotalPagoPendiente] = useState(0);
  const [showAbonoColumn, setShowAbonoColumn] = useState(false);
  const [saldoAfavor, setSaldoAfavor] = useState(0);
  const [hasSaldo, setHasSaldo] = useState(false);
  const [saldo, setSaldo] = useState(0);
  const [lang, setLang] = useState<string>(
    localStorage.getItem("langSelect")
      ? localStorage.getItem("langSelect")!
      : "en"
  );

  const { loading, error, data, refetch } = useQuery(GET_USER, {
    variables: { usuarioId: currentUser.id },
    onCompleted(data) {
      console.log(data);
      let cuotas = [];
      let newfield: any;
      let currentContacto;
      let recargo = 0;
      let total = 0;
      let totalRecargos = 0;
      let recargosAnteriores = 0;
      let abonosAnteriores = 0;
      let hasSaldo = data.viewer.usuario.habitaResidente.habitacion.saldo
        ? true
        : false;
      let saldo = hasSaldo
        ? data.viewer.usuario.habitaResidente.habitacion.saldo
        : 0;
      let saldoRestante = saldo;
      let pagoPorCuota = 0;

      setSaldoAfavor(saldo);
      setHasSaldo(hasSaldo);

      data.viewer.usuario.habitaResidente.habitacion.cuotas.edges.map(
        (input: any, index: any) => {
          //recargo = 0;
          totalRecargos = 0;
          recargosAnteriores = 0;
          abonosAnteriores = 0;
          recargo = 0;
          //saldo = 0;
          pagoPorCuota = 0;

          input.node.recargos.edges.map((value: any, index: any) => {
            recargosAnteriores += value.node.cantidad;
          });

          input.node.pagos.edges.map((value: any, index: any) => {
            abonosAnteriores += value.node.monto;
          });

          if (abonosAnteriores > 0) {
            setShowAbonoColumn(true);
          }

          newfield = {
            id: input.node.id,
            fecha: input.node.fecha,
            concepto: input.node.concepto,
            cargo: input.node.monto,
            interes: recargo,
            nuevoCargo: input.node.monto,
            anteriorRecargo: recargosAnteriores,
            nuevoRecargo: recargo,
            nuevoTotal:
              recargo +
              input.node.monto +
              recargosAnteriores -
              (abonosAnteriores + saldoRestante),
            isAdded: true,
            focus: "",
            restante:
              recargo +
              input.node.monto +
              recargosAnteriores -
              (abonosAnteriores + saldoRestante),
            pago: 0,
            descuento: 0,
            hasDiscount: false,
            discountTitle: "",
            abono: abonosAnteriores,
          };
          total +=
            recargo +
            input.node.monto +
            recargosAnteriores -
            (abonosAnteriores + saldoRestante);
          setCuotasPendientes((current) => [
            ...current,
            {
              id: input.node.id,
              fecha: input.node.fecha,
              concepto: input.node.concepto,
              cargo: input.node.monto,
              interes: recargo,
              nuevoCargo: input.node.monto,
              anteriorRecargo: recargosAnteriores,
              nuevoRecargo: recargo,
              categoria: input.node.concepto,
              nuevoTotal:
                recargo +
                input.node.monto +
                recargosAnteriores -
                (abonosAnteriores + saldoRestante),
              isAdded: true,
              focus: "",
              restante:
                recargo +
                input.node.monto +
                recargosAnteriores -
                (abonosAnteriores + saldoRestante),
              pago: 0,
              descuento: 0,
              hasDiscount: false,
              discountTitle: "",
              abono: abonosAnteriores,
            },
          ]);
          //setTotalPagoPendiente();
          setTotalPagoPendiente(total * -1);
          setNumber(total);
          setSaldo(total);
          console.log(
            "newfield",
            recargo,
            input.node.monto,
            recargosAnteriores
          );
        }
      );

      console.log(cuotasPendientes);

      //reset();
    },
    onError(error) {
      //reset();
    },
  });

  const [
    uploadRequest,
    { data: DataUpload, loading: LoadingUpload, error: ErrorUpload },
  ] = useMutation(SINGLE_UPLOAD_MUTATION, {
    onCompleted(data) {
      const fileName = `/app-assets/uploads/${data.uploadFile.fileName}`;

      setImage(fileName);
    },
  });

  const uploadFile = async () => {
    if (!file) return;
    try {
      const res = await uploadRequest({
        variables: { file },
        //,refetchQueries: [{ query: GET_PHOTOS_QUERY }],
      });
      if (res.data) {
        setImageChange(true);
        //setFile(null);
        setTimeout(() => console.log("Succes"), 300);
      }
    } catch (err) {
      console.error(err);
    }
  };

  function setPreview(file: any) {
    setFile(file.target.files[0]);
  }

  useIonViewWillEnter(() => {
    refetch();
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("payments")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <div className="spin">
            <IonSpinner></IonSpinner>
          </div>
        ) : (
          <>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{t("upload-vouchers")}</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>{t("payments-information")}</IonCardContent>

              <IonButton
                fill="outline"
                expand="block"
                routerLink={"/page/pagos/subir"}
              >
                <IonIcon icon={addIcon} slot="start" />
                {t("new-payment-proof")}
              </IonButton>
            </IonCard>

            {data?.viewer.usuario.comprobantes.edges.map((record: any) => (
              <IonCard key={record.node.id}>
                <IonItem>                  
                  <IonImg
                    style={{ maxWidth: "100%", height: "186px", width: "100%" }}
                    src={`${config.API_URL}${record.node.urlImagen}`}
                  />
                </IonItem>
                <IonCardHeader>
                  <IonCardSubtitle>
                    {Moment(record.node.fecha).locale(lang).format("LLLL")}{" "}
                  </IonCardSubtitle>
                  <IonCardTitle color={"warning"} style={{}}>
                    Estatus: {record.node.estatus}{" "}
                  </IonCardTitle>
                </IonCardHeader>

                <IonCardContent>{record.node.comentario}</IonCardContent>

                {/*<IonButton fill="outline" expand="block" routerLink={'/page/pagos/subir'}>
                <IonIcon icon={addIcon} slot="start" />
                  Nuevo Comprobante de Pago
            </IonButton>*/}
              </IonCard>
            ))}

            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{t("payment-transfer")}</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                <IonItem>
                  <IonLabel position="floating">{t("amount-to-pay")}:</IonLabel>
                  <IonInput
                    type="number"
                    value={number}
                    placeholder="00000.00"
                    onIonChange={(e) =>
                      setNumber(parseInt(e.detail.value!, 10))
                    }
                  ></IonInput>
                </IonItem>
              </IonCardContent>

              <IonButton
                fill="outline"
                expand="block"
                routerLink={"/page/pagos/subir"}
              >
                <IonIcon icon={printIcon} slot="start" />
                {t("print")}
              </IonButton>
            </IonCard>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default withTranslation()(PagosPage);
