import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonLoading,
  IonBackButton,
  IonSpinner,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { pin, wifi, wine, warning, walk } from "ionicons/icons";
import { gql, useQuery, useMutation } from "@apollo/client";
import AuthService from "../services/auth.service";
import { useParams, useHistory } from "react-router";
import { getRandomColor, createImageFromInitials } from "../components/Utils";
import config from "../config";

import "./Pages.css";

const GET_CATEGORIAS_BLOG = gql`
  query categoriablog($categoriablogId: String) {
    viewer {
      categoriaBlog(categoriaBlogId: $categoriablogId) {
        id
        nombre
        descripcion
        imagen
        estatus
        autorizacionPostear
        permitirComentarios
        blogs {
          edges {
            node {
              id
              imagen
              titulo
              texto
              fechaRegistro
              usuario {
                id
                nombre
                paterno
                materno
                color
              }
            }
          }
        }
      }
    }
  }
`;

interface PageProps {
  pageName: string | undefined;
}

const CategoriasPage: React.FC<PageProps> = ({ pageName }) => {
  const { categoriablogId } = useParams<{ categoriablogId: string }>();
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const [condominioId, setCondominioId] = useState(
    currentUser.condominioId ? currentUser.condominioId : 0
  );
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const { loading, error, data } = useQuery(GET_CATEGORIAS_BLOG, {
    variables: { categoriablogId },
    onCompleted(data) {},
    onError(error) {
      //reset();
    },
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          {loading ? (
            <IonSpinner name="dots"></IonSpinner>
          ) : (
            <IonTitle>{data.viewer.categoriaBlog.nombre}</IonTitle>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <div className="spin">
            <IonSpinner></IonSpinner>
          </div>
        ) : (
          data.viewer.categoriaBlog.blogs.edges.map((record: any) => (
            <IonCard key={record.node.id}>
              {record.node.imagen ? (
                <img
                  style={{ maxWidth: "100%", height: "186px", width: "100%" }}
                  src={`${config.API_URL}${record.node.imagen}`}
                />
              ) : (
                <img
                  style={{ maxWidth: "100%", height: "186px", width: "100%" }}
                  src={createImageFromInitials(
                    240,
                    record.node.titulo,
                    getRandomColor()
                  )}
                />
              )}

              <IonCardHeader>
                <IonCardTitle>{record.node.titulo}</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                <div
                  className="preview"
                  dangerouslySetInnerHTML={{ __html: record.node.texto }}
                ></div>
              </IonCardContent>
              <IonItem>
                <IonButton
                  routerLink={`/page/blog/${record.node.id}`}
                  fill="outline"
                  slot="end"
                >
                  Leer más ...
                </IonButton>
              </IonItem>
            </IonCard>
          ))
        )}
      </IonContent>
    </IonPage>
  );
};

export default CategoriasPage;
