import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonBackButton,
  IonSpinner,
} from "@ionic/react";
import React, { useState } from "react";
import { attach as attachIcon } from "ionicons/icons";
import { gql, useQuery } from "@apollo/client";
import AuthService from "../services/auth.service";
import { useParams } from "react-router";
import config from "../config";
import "./Pages.css";

const GET_CATEGORIAS_BLOG = gql`
  query blogDescripcion($blogDescripcionId: String) {
    viewer {
      blogDescripcion(blogDescripcionId: $blogDescripcionId) {
        id
        imagen
        titulo
        texto
        fechaRegistro
        archivos {
          edges {
            node {
              id
              archivo
              fechaSubida
            }
          }
        }
        usuario {
          id
          nombre
          paterno
          materno
          color
        }
      }
    }
  }
`;

interface PageProps {
  pageName: string | undefined;
}

const BlogPage: React.FC<PageProps> = ({ pageName }) => {
  const { blogDescripcionId } = useParams<{ blogDescripcionId: string }>();
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const [condominioId, setCondominioId] = useState(
    currentUser.condominioId ? currentUser.condominioId : 0
  );
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const { loading, error, data } = useQuery(GET_CATEGORIAS_BLOG, {
    variables: { blogDescripcionId },
    onCompleted(data) {},
    onError(error) {
      //reset();
    },
  });

  const onButtonClick = (obj: any) => {
    setIsLoading(true); // Start loading
    fetch(`${config.API_URL}/app-assets/uploads/${obj.archivo}`).then(
      (response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = obj.archivo;
          alink.click();
  
          setIsLoading(false); // Stop loading
        }).catch(() => setIsLoading(false)); // Stop loading if there's an error
      }
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          {loading ? (
            <IonSpinner name="dots"></IonSpinner>
          ) : (
            <IonTitle>{data.viewer.blogDescripcion.titulo}</IonTitle>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <div className="spin">
            <IonSpinner></IonSpinner>
          </div>
        ) : (
          <IonCard>
            <img
              style={{ maxWidth: "100%", height: "186px", width: "100%" }}
              src={`${config.API_URL}${data.viewer.blogDescripcion.imagen}`}
            />
            <IonCardHeader>
              <IonCardSubtitle>
                Por {data.viewer.blogDescripcion.usuario.nombre}
              </IonCardSubtitle>
              <IonCardTitle>{data.viewer.blogDescripcion.titulo}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
              <div
                className="preview"
                dangerouslySetInnerHTML={{
                  __html: data.viewer.blogDescripcion.texto,
                }}
              ></div>
            </IonCardContent>
            {data.viewer.blogDescripcion.archivos.edges.map((record: any) => (
              <IonItem key={record.node.id}>
                <IonIcon icon={attachIcon} slot="start" />
                <IonLabel>{record.node.archivo}</IonLabel>
                <IonButton
                  onClick={() => onButtonClick(record.node)}
                  fill="outline"
                  slot="end"
                >
                  {isLoading ? 'Descargando...' : 'Descargar Archivo'}
                </IonButton>
              </IonItem>
            ))}
          </IonCard>
        )}
      </IonContent>
    </IonPage>
  );
};

export default BlogPage;
